import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import "./_dist/app.css";
import ScrollToTopOnArrowKey from "./utils/ScrollToTopOnArrowKey";
import useIsCurrentPath from "./hooks/IsCurrentPath";

const MainLayout= lazy(()=> import("./domains/erathisFP/layouts/MainLayout"))
const EsdCompBaseComponent = lazy(() => import("./domains/erathisFP/pages/EsdCompBaseComponent"));
const EsdCompContactFeature=lazy(()=> import("./domains/erathisFP/pages/EsdCompContactFeature"));
const EsdCompkwkFeaturedComponent= lazy(()=>import("./domains/erathisFP/pages/EsdCompkwkFeaturedComponent"));
const EsdCompPartnershipComponent=lazy(()=> import("./domains/erathisFP/pages/EsdCompPartnershipComponent"));
const EsdCompEaComponent=lazy(()=> import("./domains/erathisFP/pages/EsdCompEaComponent"));
const EsdCompEaContent=lazy(()=>import("./domains/erathisFP/pages/EsdCompEaContent"));
// const PortfolioLayout = lazy(()=> import("./domains/erathisFP/layouts/PortfolioLayout"));
// const EsdCompPortfolio=lazy(()=> import("./domains/erathisFP/pages/EsdCompPortfolio"));
// const EsdCompPortfolioAla= lazy(()=>import("./domains/erathisFP/pages/EsdCompPortfolioAla"));
// const EsdCompProject=lazy(()=> import("./components/portfolio/EsdCompProject"));
// const EsdCompPortfolioCardDetails=lazy(()=> import("./components/portfolio/EsdCompPortfolioCardDetails"))
// const EsdCompPortfolioCardList=lazy(()=>import("./components/portfolio/EsdCompPortfolioCardList"))

function App() {
  const isContact = useIsCurrentPath("/contact");
  return (
    <div id="app" className={`app-container ${isContact&& "app-container-contact"}`}>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<EsdCompBaseComponent />} />
          <Route path="/contact" element={<EsdCompContactFeature />} />
          <Route path="/featured" element={<EsdCompkwkFeaturedComponent />} />
          <Route
            path="/partnerships"
            element={<EsdCompPartnershipComponent />}
          />
          <Route path="/executive" element={<EsdCompEaComponent />} />
          <Route
            path="/board"
            element={<EsdCompEaContent variant={"board"} />}
          />
          <Route
            path="/corporate"
            element={<EsdCompEaContent variant={"corporate"} />}
          />

          {/* <Route path="/portfolio" element={<PortfolioLayout />}>
            <Route index element={<EsdCompPortfolio />} />
            <Route path="/portfolio/ala" element={<EsdCompPortfolioAla />} />
            <Route
              path="/portfolio/portfolioList"
              element={<EsdCompPortfolioCardList />}
            />
            <Route path="/portfolio/project" element={<EsdCompProject />} />
            <Route
              path="/portfolio/portfoliodetails"
              element={<EsdCompPortfolioCardDetails />}
            />
          </Route> */}
        </Route>
      </Routes>
      <ScrollToTopOnArrowKey scrollElementId="app"/>
    </div>
  );
}

export default App;
