import React, { useEffect } from 'react';

interface KeyboardScrollProps {
  scrollElementId: string;
}

const KeyboardScroll: React.FC<KeyboardScrollProps> = ({ scrollElementId }) => {
  const handleKeyDown = (event: KeyboardEvent) => {
    const scrollElement = document.getElementById(scrollElementId);

    if (!scrollElement) return;

    if (event.key === 'ArrowUp') {
      // Scroll up
      scrollElement.scrollTop -= 20; // You can adjust the scroll amount
    } else if (event.key === 'ArrowDown') {
      // Scroll down
      scrollElement.scrollTop += 20; // You can adjust the scroll amount
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [scrollElementId]);

  return null;
};

export default KeyboardScroll;

